.Icon {
    font-size: 10px;
    top: 15px;
}

.linkTitle {
    display: inline;
    padding-left: 10px;
    word-wrap: normal;
    font-size: 13px;
    font-family: "Roboto";
    letter-spacing: 2px;
}

.linkTitle:hover {
    text-decoration: none;
}

.ListItem {
    padding-top: 10px;
    margin-bottom: 15px;
    margin-right: 20px;
}

.hreflink {
    padding: 10px;
    color: #ececee;

}

.hreflink:hover {
    text-decoration: none;
    background-color: #4c5060;
    color: #f8941e;
}