.Logo {
    padding: 1px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo img {
    max-height: 100%;
    max-width: 100%;
}