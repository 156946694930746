.leftSideBar {
    background-color: #5a5f70;
    position: sticky;
}

.topBar {
    text-align: center;
    background-color: #cacdd4;
    height: 40px;
    position: sticky;
}

.main_content {
    padding: 25px;
    background-color: #ececed;
}