.h1Text {
    max-height: 33px;
}
.topBarRow {
    text-align: center;
    background-color: #5a5f70;
}
.TopMenu {
    background-color: #cacdd4;
    height: 40px;
}