.copy, .version {
    text-align: center;
    margin-bottom: 0px;
    font-size: 12px;
}

.version {
    text-align: right;
}

.Footer {
    height: 19px;
    background-color: #4c5060;
    text-align: center;
    color: #ececed;
}