.mainleftbar {
    color: #ececee;
    min-height: 94vh;
    font-family: 'open sans condensed';
    letter-spacing: 3px;
    text-transform: lowercase;
    font-variant: small-caps;
    font-size: 13px;
    font-weight: lighter;
}

.logo {
    text-align: center;
    padding-bottom: 20px;
}